import React from 'react';

import InfoCard from '../InfoCard';

import styles from './index.module.less';

const list = [
  {
    id: 1,
    imgUrl: '/images/ESS1.png',
    text: '即开即用',
  },
  {
    id: 2,
    imgUrl: '/images/ESS2.png',
    text: '安全可靠',
  },
  {
    id: 3,
    imgUrl: '/images/ESS3.png',
    text: '弹性伸缩',
  },
  {
    id: 4,
    imgUrl: '/images/ESS4.png',
    text: '易于维护',
  },
];

const infoList = [
  {
    id: 1,
    title: '全信息集成',
    desc:
      '互联集成工业生产数据、管理数据和运营数据等，通过数据清洗和处理，形成统一定义的对象化模型数据库。',
  },
  {
    id: 2,
    title: '数据资产管理',
    desc:
      '面向对象元数据的清洗、加工和萃取，提供基于业务流程模型的数据价值提取，实现多元数据融合后的数据质量和安全管理。',
  },
  {
    id: 3,
    title: '智能服务运行管理',
    desc:
      '提供系统公共基础服务和APP容器运行框架，支持服务注册、部署和管理功能。',
  },
  {
    id: 4,
    title: '大数据分析',
    desc:
      '基于多元、海量数据的综合应用和分析，提供大数据分析算法和挖掘工具，具备云存储服务和大规模并发处理能力。',
  },
  {
    id: 5,
    title: '人工智能引擎',
    desc:
      '提供智能引擎服务，作为工厂智慧控制和调度的核心大脑，实现工厂工艺寻优、操作寻优和运营优化的目标。',
  },
  {
    id: 6,
    title: '工业APP开发环境',
    desc:
      '基于工业数据平台，提供组态式开发环境，对业务数据的分析和展示提供可视化模板库，可实现工业APP应用的快速开发和设计。',
  },
  {
    id: 7,
    title: '应用商店',
    desc:
      '提供平台开放服务，发展生态合作伙伴，打造全产业链工业APP库，实现工业数据生态圈。',
  },
  {
    id: 8,
    title: '移动应用协同',
    desc:
      '提供工业生产办公移动化协同服务，实现工厂内、外应用协作与专业知识交流分享。',
  },
  {
    id: 9,
    title: '工业互联云平台',
    desc:
      '提供云、企、端三层应用的统一架构，支持设备物联，私有云、公有云平台部署。',
  },
];

const ESSIntroduce = () => {
  return (
    <div className={styles['ESS-introduce']}>
      <div className={styles.content}>
        <h4>ESS - 在线工业互联网PaaS服务</h4>
        <ul className={styles.ulWrap}>
          {list.map((i) => (
            <li key={i.id}>
              <img alt="" src={i.imgUrl} />
              <div>{i.text}</div>
            </li>
          ))}
        </ul>

        <div className={styles.info}>
          supOS云服务ESS（Elastic supOS
          Server），是基于国内首个自主知识产权的工业操作系统supOS提供的在线服务，赋能企业以集成化、数字化、智能化手段解决生产控制、生产管理和企业经营的综合问题，打造服务于企业、赋能于工业的智慧大脑。
        </div>

        <h4 className={styles.subtitle}>ESS能做什么？</h4>
        <div className={styles['info-card-wrap']}>
          {infoList.map((i) => {
            return <InfoCard key={i.id} title={i.title} desc={i.desc} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default ESSIntroduce;
