import React, { useState } from 'react';
import classnames from 'classnames';

import styles from './index.module.less';

const solutionList = [
  {
    id: 46,
    nodeCode: '2ND5',
    nodeName: '通用解决方案',
    ownerOperator: 1,
    businessModule: 1,
    clientType: 1,
    level: 2,
    iconUrl: '',
    hrefUrl: 'https://store-cloud.supos.com/app-solution?type=common_solutions',
    status: 1,
    sortOrder: 1,
    description:
      '基于先进的云计算、大数据、AI等技术，面向工业场景提供整套技术解决方案，助力企业降本增效，方案覆盖智能制造、智慧远程运维、智慧园区、智能环保、智能大数据等多个领域。',
    parentNodeCode: '6I9B',
    children: [
      {
        id: 47,
        nodeCode: 'cIRZ',
        nodeName: '智慧园区',
        ownerOperator: 1,
        businessModule: 1,
        clientType: 1,
        level: 3,
        iconUrl: '',
        hrefUrl:
          'https://store-cloud.supos.com/app-solution-detail/62220fa333dc8cb8ce6b/',
        status: 1,
        sortOrder: 1,
        description: '',
        parentNodeCode: '2ND5',
        children: [],
      },
      {
        id: 48,
        nodeCode: 'j1Qx',
        nodeName: '智慧远程运维',
        ownerOperator: 1,
        businessModule: 1,
        clientType: 1,
        level: 3,
        iconUrl: '',
        hrefUrl:
          'https://store-cloud.supos.com/app-solution-detail/ad9a4fec79ab0b9e5731/',
        status: 1,
        sortOrder: 2,
        description: '',
        parentNodeCode: '2ND5',
        children: [],
      },
      {
        id: 49,
        nodeCode: 'NxsL',
        nodeName: '智能制造',
        ownerOperator: 1,
        businessModule: 1,
        clientType: 1,
        level: 3,
        iconUrl: '',
        hrefUrl:
          'https://store-cloud.supos.com/app-solution-detail/347bbb5041eb84fdfb93/',
        status: 1,
        sortOrder: 3,
        description: '',
        parentNodeCode: '2ND5',
        children: [],
      },
      {
        id: 50,
        nodeCode: '29Ui',
        nodeName: '智能环保',
        ownerOperator: 1,
        businessModule: 1,
        clientType: 1,
        level: 3,
        iconUrl: '',
        hrefUrl:
          'https://store-cloud.supos.com/app-solution-detail/dc54edb6104fa767f272/',
        status: 1,
        sortOrder: 4,
        description: '',
        parentNodeCode: '2ND5',
        children: [],
      },
      {
        id: 51,
        nodeCode: 'sZcO',
        nodeName: '智能大数据',
        ownerOperator: 1,
        businessModule: 1,
        clientType: 1,
        level: 3,
        iconUrl: '',
        hrefUrl:
          'https://store-cloud.supos.com/app-solution-detail/cecac53f0ca114903a4f/',
        status: 1,
        sortOrder: 5,
        description: '',
        parentNodeCode: '2ND5',
        children: [],
      },
    ],
  },
  {
    id: 45,
    nodeCode: 'kSFy',
    nodeName: '行业解决方案',
    ownerOperator: 1,
    businessModule: 1,
    clientType: 1,
    level: 2,
    iconUrl: '',
    hrefUrl:
      'https://store-cloud.supos.com/app-solution?type=industry_solutions',
    status: 1,
    sortOrder: 2,
    description:
      '以supOS为底座，在行业know-how的深刻理解基础上，面向企业构建生产与经营一体化应用平台，赋能企业向信息化、数字化、智能化转型升级，方案覆盖汽车零部件、机械加工、电子电器、金属制品、服装纺织、化工、石化、水泥、冶金、能源等多个行业领域。',
    parentNodeCode: '6I9B',
    children: [
      {
        id: 52,
        nodeCode: '6Phl',
        nodeName: '汽车零部件',
        ownerOperator: 1,
        businessModule: 1,
        clientType: 1,
        level: 3,
        iconUrl: '',
        hrefUrl:
          'https://store-cloud.supos.com/app-solution-detail/cdf68bada0bd1c8ae343/',
        status: 1,
        sortOrder: 1,
        description: '',
        parentNodeCode: 'kSFy',
        children: [],
      },
      {
        id: 53,
        nodeCode: 'yaiw',
        nodeName: '金属制品',
        ownerOperator: 1,
        businessModule: 1,
        clientType: 1,
        level: 3,
        iconUrl: '',
        hrefUrl:
          'https://store-cloud.supos.com/app-solution-detail/091f84dc68b0d22eed6b/',
        status: 1,
        sortOrder: 2,
        description: '',
        parentNodeCode: 'kSFy',
        children: [],
      },
      {
        id: 54,
        nodeCode: 'uXMp',
        nodeName: '智能冶金',
        ownerOperator: 1,
        businessModule: 1,
        clientType: 1,
        level: 3,
        iconUrl: '',
        hrefUrl:
          'https://store-cloud.supos.com/app-solution-detail/f1a62418dc644cc763e1/',
        status: 1,
        sortOrder: 3,
        description: '',
        parentNodeCode: 'kSFy',
        children: [],
      },
      {
        id: 55,
        nodeCode: 'gonF',
        nodeName: '包装行业',
        ownerOperator: 1,
        businessModule: 1,
        clientType: 1,
        level: 3,
        iconUrl: '',
        hrefUrl:
          'https://store-cloud.supos.com/app-solution-detail/ddce5101e2099bc817d8/',
        status: 1,
        sortOrder: 4,
        description: '',
        parentNodeCode: 'kSFy',
        children: [],
      },
      {
        id: 56,
        nodeCode: '79lz',
        nodeName: '智能水泥',
        ownerOperator: 1,
        businessModule: 1,
        clientType: 1,
        level: 3,
        iconUrl: '',
        hrefUrl:
          'https://store-cloud.supos.com/app-solution-detail/26c0b1c268aaadef191a/',
        status: 1,
        sortOrder: 5,
        description: '',
        parentNodeCode: 'kSFy',
        children: [],
      },
    ],
  },
];

const Solution = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const sty1 = classnames(styles.item, {
    [styles.selected]: selectedIndex === 0,
  });

  const sty2 = classnames(styles.item, {
    [styles.selected]: selectedIndex === 1,
  });

  const info = solutionList[selectedIndex];

  if (!info) return <div></div>;

  return (
    <div className={styles.solution}>
      <div className={styles.title}>
        以行业Know-how为基础的解决方案，满足企业数字化经营与生产
      </div>
      <div className={styles.content}>
        <div className={styles.menu}>
          <div className={sty1} onClick={() => setSelectedIndex(0)}>
            通用解决方案
          </div>
          <div className={sty2} onClick={() => setSelectedIndex(1)}>
            行业解决方案
          </div>
        </div>
        <div className={styles.info}>
          <div> {info.nodeName}</div>
          <div>{info.description}</div>
          <div className={styles.tagWrap}>
            {info.children.map((i) => (
              <a
                key={i.id}
                className={styles.tag}
                target="_blank"
                href={i.hrefUrl}
                rel="noreferrer"
              >
                {i.nodeName}
              </a>
            ))}
          </div>
          {/* <div className={styles.company}>
            {info.companyLogo.map((i, index) => (
              <img src="" alt="" key="index" />
            ))}
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Solution;
