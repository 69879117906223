import React from 'react';

import styles from './index.module.less';

const InfoCard = ({ title, desc }) => {
  return (
    <div className={styles['info-card']}>
      <div>{title}</div>
      <div>{desc}</div>
    </div>
  );
};

export default InfoCard;
