import React from 'react';
import Link from 'next/link';

import styles from './index.module.less';

const ProductPromotion = ({ promotionList }) => {
  return (
    <div className={styles['product-promotion']}>
      <div className={styles.content}>
        {promotionList.map((i) => {
          return (
            <div key={i.id} className={styles.box}>
              <div className={styles.inner}>
                <p>{i.sloganTitle}</p>
                {i.hrefUrl ? (
                  <Link href={i.hrefUrl}>{i.subTitle}</Link>
                ) : (
                  <span>{i.subTitle}</span>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ProductPromotion;
