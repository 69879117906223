import React from 'react';

import { Carousel, Tag } from 'antd';

import styles from './index.module.less';

const renderContent = (i) => (
  <div
    className={styles.content}
    style={{ backgroundImage: `url(${i.sloganPhoto})` }}
  >
    <div className={styles.center}>
      <div className={styles.title}>
        <span>{i.sloganTitle}</span>
        {i.subTitle ? (
          <Tag color="rgba(0, 95, 225, 1)">{i.subTitle}</Tag>
        ) : null}
      </div>
      <div
        dangerouslySetInnerHTML={{ __html: i.sloganInfo }}
        className={styles.desc}
      />
    </div>
  </div>
);

const Banner = ({ bannerList }) => {
  return (
    <div className={styles.banner}>
      <Carousel autoplay>
        {bannerList.map((i) =>
          i.hrefUrl ? (
            <a href={i.hrefUrl} key={i.id} className={styles.box}>
              {renderContent(i)}
            </a>
          ) : (
            <div key={i.id} className={styles.box}>
              {renderContent(i)}
            </div>
          )
        )}
      </Carousel>
    </div>
  );
};

export default Banner;
