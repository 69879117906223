/*
 * @Author: 罗侠 luoxia@supos.com
 * @Date: 2021-07-06 13:19:54
 * @LastEditors: 罗侠 luoxia@supos.com
 * @LastEditTime: 2023-03-29 15:00:03
 * @FilePath: \cloud-console-ssr-frontend\src\pages\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React from 'react';
import { observer, inject } from 'mobx-react';
import Head from 'next/head';

import NormalLayout from '../layouts/NormalLayout';

import Certification from '../components/Certification';

import Banner from '../pageComponents/Index/Banner';
import ProductPromotion from '../pageComponents/Index/ProductPromotion';
import ESSIntroduce from '../pageComponents/Index/ESSIntroduce';
import CloudProduct from '../pageComponents/Index/CloudProduct';
import Solution from '../pageComponents/Index/Solution';
import SelectCloud from '../pageComponents/Index/SelectCloud';
import Achievement from '../pageComponents/Index/Achievement';

import { getIndexPageConfiguration } from '../service';

import styles from './index.module.less';

@inject('appGlobalModel')
@inject('modelIndex')
@NormalLayout
@observer
class IndexPage extends React.Component {
  constructor(props) {
    super(props);
    this.globalStore = props.appGlobalModel;
    this.store = props.modelIndex;
  }

  render() {
    const { solutionConfigure = {} } = this.globalStore;
    const { bannerList, promotionList } = this.store;

    return (
      <div className={styles['page-home']}>
        <Head>
          <title></title>
          <meta
            name="keywords"
            content="工业互联网，云服务，云平台，工业云，数字化转型，行业云，上云，云计算"
          ></meta>
          {/* <meta
            name="description"
            content="蓝卓云是蓝卓公司倾力打造的云战略品牌，为客户提供领先的云工业操作系统服务、大数据分析、人工智能以及行业云等云计算服务，助力企业进行数字化转型。"
          ></meta> */}
        </Head>
        <Banner bannerList={bannerList} />
        <ProductPromotion promotionList={promotionList} />
        <ESSIntroduce />
        <CloudProduct />
        <Solution solutionList={solutionConfigure.children} />
        <SelectCloud />
        <Certification />
        <Achievement />
      </div>
    );
  }
}

export async function getStaticProps() {
  // 获取banner配置信息
  const { items: bannerList } = await getIndexPageConfiguration({
    ownerOperator: 1, // 运营方 ：1-蓝卓，2-其他
    businessModule: 1, // 1-首页，2-其他"
    sloganType: 1, // 1-banner，2-营销活动，3-其他
  });

  // 获取推广配置信息
  const { items: promotionList } = await getIndexPageConfiguration({
    ownerOperator: 1, // 运营方 ：1-蓝卓，2-其他
    businessModule: 1, // 1-首页，2-其他"
    sloganType: 2, // 1-banner，2-营销活动，3-其他
  });

  // 通过 props 参数向页面传递数据
  return {
    props: {
      staticProps: {
        appGlobalModel: {},
        modelIndex: {
          bannerList: bannerList || [],
          promotionList: promotionList || [],
        },
      },
    },
    revalidate: 10,
  };
}

export default IndexPage;
