import React from 'react';

import styles from './index.module.less';

const proList = [
  {
    id: 1,
    imgUrl: '/images/pro1.png',
    title: '制造协同云',
    desc: '制造协同云是面向离散制造的高效云端制造协同平台。专注于汽配、金属加工、机械加工等垂直行业，提供从客户下单、原料入厂到成品出厂全链路数字化协同管理的SaaS服务，以低成本轻量化的方式帮助企业提升生产效率、产品质量，缩短交付周期，助力企业实现数字化转型。',
  },
  {
    id: 2,
    imgUrl: '/images/pro2.png',
    title: '云报工',
    desc: '云报工（生产报工云服务），一款云端SaaS化产品，采用先进的条码和智能数据采集技术，无需安装应用，支持多屏协同，通过手机即可便捷报工、实时监控生产、精准核算绩效，帮助企业降本增效，实现数字化协同管理。',
  },
  {
    id: 3,
    imgUrl: '/images/pro3.png',
    title: '云设备管理',
    desc: '云设备管理是一款设备数字化管理软件，面向工业企业生产设备管理的用户，帮助工业企业解决设备巡检、设备报修、设备保养等管理难题，提升企业设备管理效率。',
  },
  {
    id: 4,
    imgUrl: '/images/pro4.png',
    title: '制造可视化',
    desc: '制造可视化是一项工厂数字化服务，将生产企业的订单、采购、生产、质检等数据全面可视化，同时通过数据分析帮助决策者及时发现生产存在的问题，提高生产经营效率。',
  },
];

const CloudProduct = () => {
  return (
    <div className={styles['cloud-product']}>
      <div className={styles.top}>丰富的云服务产品，助力企业数字化管理</div>
      <div className={styles.bottom}>
        {proList.map((i) => (
          <div key={i.id} className={styles.imgWrap}>
            <img src={i.imgUrl} alt="" />
            <div className={styles.info}>
              <div className={styles.title}>{i.title}</div>
              <div className={styles.desc}>{i.desc}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CloudProduct;
