import React from 'react';

import styles from './index.module.less';

const list = [
  {
    id: 1,
    imgUrl: '/images/leader.png',
    text: '领先技术',
    desc: '基于中控集团近30年的工业领域积累，自主研发我国首个具备自主知识产权的supOS工业操作系统。',
  },
  {
    id: 2,
    imgUrl: '/images/safe.png',
    text: '安全可靠',
    desc: '蓝卓云通过人工智能、大数据分析、攻防技术等先进技术进行实时监测与安全防护；已获得可信云服务认证、ISO9001、ISO14001等多项认证，为企业保驾护航。',
  },
  {
    id: 3,
    imgUrl: '/images/universal.png',
    text: '全球化平台部署',
    desc: '产品覆盖全国24个省市地区及白俄、越南、泰国等国家，涵盖20+个行业包括石化、化工、建材、冶金、精细化工、制药、电力、汽车零部件、金属加工、电子电器、印染纺织服装、装备制造等。',
  },
  {
    id: 4,
    imgUrl: '/images/abundant.png',
    text: '丰富的生态体系',
    desc: '蓝卓以平台+生态的模式携手生态合作伙伴，共建数字生态体系。',
  },
  {
    id: 5,
    imgUrl: '/images/low.png',
    text: '低投入，低风险',
    desc: '按需付费，费用是传统软件的1/10，零运维，免费升级，有效降低TCO。',
  },
  {
    id: 6,
    imgUrl: '/images/easy.png',
    text: '开箱即用',
    text2: '快速投入生产',
    desc: '企业无需自建IDC机房，即开即用，加快企业数字化应用的速度。',
  },
];

const SelectCloud = () => {
  return (
    <div className={styles['select-cloud']}>
      <div className={styles.title}>致力于提供优质、可靠的工业互联网云服务</div>
      <div className={styles.box}>
        {list.map((i) => {
          return (
            <div className={styles['img-wrap']} key={i.id}>
              <img src={i.imgUrl} alt="" />
              <div className={styles.desc}>
                <div className={styles.textWrap}>
                  <div>{i.text}</div>
                  <div>{i.text2}</div>
                </div>
              </div>

              <div className={styles['hover-desc']}>
                <div>{i.text}</div>
                <div />
                <div>{i.desc}</div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SelectCloud;
