import React from 'react';
import { Carousel } from 'antd';

import styles from './index.module.less';

const settings = {
  infinite: true,
  slidesToShow: 4,
  speed: 500,
  rows: 2,
  slidesPerRow: 2,
  arrows: true,
};

const achievementList = [
  {
    id: 0,
    imgUrl: '/images/partners-0.png',
  },
  {
    id: 1,
    imgUrl: '/images/partners-1.png',
  },
  {
    id: 2,
    imgUrl: '/images/partners-2.png',
  },
  {
    id: 3,
    imgUrl: '/images/partners-3.png',
  },
  {
    id: 4,
    imgUrl: '/images/partners-4.png',
  },
  {
    id: 5,
    imgUrl: '/images/partners-5.png',
  },
  {
    id: 6,
    imgUrl: '/images/partners-6.png',
  },
  {
    id: 7,
    imgUrl: '/images/partners-7.png',
  },
  {
    id: 8,
    imgUrl: '/images/partners-8.png',
  },
  {
    id: 9,
    imgUrl: '/images/partners-9.png',
  },
  {
    id: 10,
    imgUrl: '/images/partners-10.png',
  },
  {
    id: 11,
    imgUrl: '/images/partners-11.png',
  },
  {
    id: 12,
    imgUrl: '/images/partners-12.png',
  },
  {
    id: 13,
    imgUrl: '/images/partners-13.png',
  },
  {
    id: 14,
    imgUrl: '/images/partners-14.png',
  },
  {
    id: 15,
    imgUrl: '/images/partners-15.png',
  },
  {
    id: 16,
    imgUrl: '/images/partners-16.png',
  },
  {
    id: 17,
    imgUrl: '/images/partners-17.png',
  },
  {
    id: 18,
    imgUrl: '/images/partners-18.png',
  },
  {
    id: 19,
    imgUrl: '/images/partners-19.png',
  },
  {
    id: 20,
    imgUrl: '/images/partners-20.png',
  },
  {
    id: 31,
    imgUrl: '/images/partners-31.png',
  },
  {
    id: 21,
    imgUrl: '/images/partners-21.png',
  },
  {
    id: 22,
    imgUrl: '/images/partners-22.png',
  },

  {
    id: 23,
    imgUrl: '/images/partners-23.png',
  },
  {
    id: 24,
    imgUrl: '/images/partners-24.png',
  },
  {
    id: 25,
    imgUrl: '/images/partners-25.png',
  },
  {
    id: 26,
    imgUrl: '/images/partners-26.png',
  },
  {
    id: 27,
    imgUrl: '/images/partners-27.png',
  },
  {
    id: 28,
    imgUrl: '/images/partners-28.png',
  },
  {
    id: 29,
    imgUrl: '/images/partners-29.png',
  },
  {
    id: 30,
    imgUrl: '/images/partners-30.png',
  },
];

const Achievement = () => {
  return (
    <div className={styles.achievement}>
      <div className={styles.center}>
        <div className={styles.title}>跨行业跨领域企业的信赖选择</div>
        <ul className={styles.content}>
          <Carousel autoplay {...settings}>
            {achievementList.map((i) => (
              <div key={i.id}>
                <img className={styles.logo} src={i.imgUrl} alt="" />
              </div>
            ))}
          </Carousel>
        </ul>
      </div>
    </div>
  );
};

export default Achievement;
